/* eslint-disable no-unreachable */
<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Task_ID')" prop="pushId">
                <el-input
                  v-model.trim="formInline.pushId"
                  maxlength="19"
                  placeholder="请输入准确信息"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Push_title')" prop="pushTitle">
                <el-input
                  v-model.trim="formInline.pushTitle"
                  maxlength="20"
                  placeholder="请输入准确信息"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Push_classification')" prop="pushCategoryInfo">
                <el-select multiple clearable v-model="formInline.pushCategory" placeholder="全部">
                  <el-option label="系统通知" :value="1"></el-option>
                  <el-option label="活动通知" :value="2"></el-option>
                  <el-option label="资讯热点" :value="3"></el-option>
                  <el-option label="商品推介" :value="4"></el-option>
                  <!-- <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>-->
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Target_application')" prop="targetApplication">
                <el-select
                  multiple
                  clearable
                  v-model="formInline.targetApplication"
                  placeholder="全部"
                >
                  <el-option label="全国爱泊车" :value="1" />
                  <el-option label="石家庄爱泊车" :value="2" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Push_status')" prop="pushStatus">
                <el-select multiple clearable v-model="formInline.pushStatus" placeholder="全部">
                  <el-option label="已保存" :value="1" />
                  <el-option label="待推送" :value="2" />
                  <el-option label="已推送" :value="3" />
                  <!-- <el-option label="已作废"
                         :value="4" /> -->
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Push_time')" prop="pushTime">
                <el-date-picker
                  v-model="formInline.pushTime"
                  type="datetimerange"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <el-form-item :label="$t('searchModule.last_Change_time')" prop="lastChangeTime">
                <el-date-picker
                  v-model="formInline.lastChangeTime"
                  type="datetimerange"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </AuthorityComponent>
              <!-- <el-button type="primary" @click="pageNum=1;searchData()" :loading="loading" v-if="$route.meta.authority.button.query">{{ $t('button.search') }}</el-button> -->
              <el-button @click="resetForm('formInline')" icon="el-icon-delete" type="info"
                >{{ $t('button.reset') }}</el-button
              >
              <!-- <el-button type="success" @click="createPush()" v-if="$route.meta.authority.button.add">创建推送</el-button> -->
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.add']"
                type="primary"
                @click="createPush()"
                icon="el-icon-plus"
                >创建推送
              </AuthorityComponent>
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          border
          v-loading="loading"
          :data="tableData"
          :default-sort="{ prop: 'updateTime', order: 'descending' }"
        >
          <el-table-column :label="$t('searchModule.Task_ID')" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.pushId }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.title')" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Push_classification')" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.categoryName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Target_application')" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.targetTerminalName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="跳转链接" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tooltip v-if="scope.row.jumpType === 1" placement="top" content="启动APP">
                <i class="el-icon-house" />
              </el-tooltip>
              <el-tooltip
                v-else-if="scope.row.jumpType === 2"
                placement="top"
                content="页面URL"
                show-overflow-tooltip
              >
                <i class="el-icon-link link" @click="toLink(scope.row.jumpUrl)" />
              </el-tooltip>
              <el-tooltip v-else placement="top" content="应用路径" show-overflow-tooltip>
                <i class="el-icon-s-operation" />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.state')" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.pushStatusName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.Push_time')"
            align="center"
            sortable
            prop="pushTime"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.pushTime }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.last_Operator')" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.updateUserName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.last_Change_time')"
            align="center"
            prop="updateTime"
            sortable
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.updateTime }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          /> -->
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-dropdown
                @command="handleCommand($event, scope.row)"
                v-if="
                  $route.meta.authority.button.view ||
                  ($route.meta.authority.button.edit && scope.row.pushStatus != 3) ||
                  ($route.meta.authority.button.delete && scope.row.pushStatus != 3)
                "
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1" v-if="$route.meta.authority.button.view"
                    >详情</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="2"
                    v-if="$route.meta.authority.button.edit && scope.row.pushStatus != 3"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="3"
                    v-if="$route.meta.authority.button.delete && scope.row.pushStatus != 3"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "../../../common/js/public.js";
export default {
  name: "appPushManage",
  data() {
    return {
      msgType: [],
      centerDialogVisible: false,
      rowData: "",
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      roleList: [],
      loading: false,
      detailFlag: false,
      rsTypeList: {},
      tableCols: [
        {
          prop: "pushId",
          label: "任务ID",
        },
        {
          prop: "title",
          label: "标题",
        },
        {
          prop: "category",
          label: "推送分类",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return "系统通知";
                // eslint-disable-next-line no-unreachable
                break;
              case 2:
                return "活动通知";
                // eslint-disable-next-line no-unreachable
                break;
              case 3:
                return "资讯热点";
                // eslint-disable-next-line no-unreachable
                break;
              case 4:
                return "商品推介";
                // eslint-disable-next-line no-unreachable
                break;
              default:
                return "";
                // eslint-disable-next-line no-unreachable
                break;
            }
          },
        },
        {
          prop: "targetTerminal",
          label: "目标应用",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return "全国app";
                // eslint-disable-next-line no-unreachable
                break;
              case 2:
                return "石家庄app";
                // eslint-disable-next-line no-unreachable
                break;
              default:
                return "";
                // eslint-disable-next-line no-unreachable
                break;
            }
          },
        },
        {
          prop: "jumpType",
          label: "跳转类型",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return <i class="el-icon-house" />;
              // eslint-disable-next-line no-unreachable
              case 2:
                return <i class="el-icon-link link link" />;
                // eslint-disable-next-line no-unreachable
                break;
              case 3:
                return <i class="el-icon-s-operation"></i>;
                // eslint-disable-next-line no-unreachable
                break;
              default:
                return "";
                // eslint-disable-next-line no-unreachable
                break;
            }
          },
        },
        {
          prop: "pushStatus",
          label: this.$t("list.state"),
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return "已保存";
                // eslint-disable-next-line no-unreachable
                break;
              case 2:
                return "待推送";
                // eslint-disable-next-line no-unreachable
                break;
              case 3:
                return "已推送";
                // eslint-disable-next-line no-unreachable
                break;
              default:
                return "";
                // eslint-disable-next-line no-unreachable
                break;
            }
          },
        },
        {
          prop: "pushTime",
          label: "推送时间",
          sortable: true,
        },
        {
          prop: "updateUserName",
          label: "最后操作人",
        },
        {
          prop: "updateTime",
          label: "最后修改时间",
        },
      ],
      rules: {
        content: [
          { required: true, message: "请输入发送内容", trigger: "blur" },
          { min: 3, max: 50, message: "长度在 3 到 50 个字符", trigger: "blur" },
        ],
      },
      tableData: [],
      formInline: {
        pushId: "",
        pushTitle: "",
        pushCategory: [],
        targetApplication: [],
        pushStatus: [],
        msgType: "",
        title: "",
        transitionType: "",
        pushTime: "",
        lastChangeTime: "",
      },
      formInline2: {
        content: "",
      },
      pushDateArray: [],
      lastModifyDateArray: [],
      showEdit: true,
      showDetele: true,
      pushStartTime: "",
      pushEndTime: "",
      lastStartTime: "",
      lastEndTime: "",
    };
  },
  methods: {
    toLink(url) {
      window.open(url);
    },
    // 创建推送
    createPush() {
      this.$router.push({ path: "/pushEdit" });
    },
    // 选择每行数据
    getRowData(data) {
      this.rowData = data;
    },
    // 下来菜单
    getSpinner() {
      this.$axios.get("/acb/2.0/spinner").then((res) => {
        this.msgType = res.value.messageType;
        for (let i = 0; i < this.msgType.length; i++) {
          if (this.msgType[i].type == 7) {
            this.msgType.splice(i, 1);
          }
          if (this.msgType[i].type == 8) {
            this.msgType.splice(i, 1);
          }
        }
        this.formInline.msgType = "";
      });
    },
    // 操作细则
    handleCommand(command, data) {
      if (command == 1) {
        this.$router.push({ path: "/pushDetail", query: data });
      } else if (command == 2) {
        this.$router.push({ path: "/pushEdit", query: data });
      } else if (command == 3) {
        this.delData(data);
      }
    },
    resetForm(formName) {
      this.formInline.pushId = "";
      this.formInline.pushTitle = "";
      this.formInline.pushTime = "";
      this.formInline.lastChangeTime = "";
      this.formInline.pushCategory = [];
      this.formInline.targetApplication = [];
      this.formInline.pushStatus = [];
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      if (this.formInline.pushId && !/^\d+$/.test(this.formInline.pushId)) {
        this.$alert("任务ID必须为数字", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.tableData = [];
      this.$axios
        .post("/acb/2.0/pushManage/list", {
          data: {
            pushId: this.formInline.pushId,
            title: this.formInline.pushTitle,
            categoryList: this.formInline.pushCategory,
            targetTerminalList: this.formInline.targetApplication,
            pushStatusList: this.formInline.pushStatus,
            //  categoryList: 1,
            // targetTerminalList: 1,
            // pushStatusList: 1,
            pushStartTime: this.pushStartTime,
            pushEndTime: this.pushEndTime,
            lastStartTime: this.lastStartTime,
            lastEndTime: this.lastEndTime,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.total = res.value.total * 1 || 0;
          let arr = res.value.list.map((x) => {
            if (x.pushTime == null || x.pushTime == undefined) {
              x.pushTime = "";
            }
            return x;
          });

          this.tableData = arr;
        });
    },
    formateDate(datetime) {
      // let  = "2019-11-06T16:00:00.000Z"
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() + "-" + addDateZero(d.getMonth() + 1) + "-" + addDateZero(d.getDate());
      return formatdatetime;
    },
    delData(data) {
      this.$confirm("将删除该条数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.post("/acb/2.0/pushManage/delete/" + data.pushId).then((res) => {
            if (res.state == 0) {
              this.$alert("删除成功", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              }).then(() => {
                this.searchData();
              });
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  activated() {
    this.searchData();
  },
  created() {
    // if (sessionStorage.searchFlag == 1) {
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    // }
    // this.searchData();
    // this.getSpinner();
  },
  watch: {
    pushTimeList(val) {
      if (val) {
        this.pushStartTime = this.$moment(val[0]).format("YYYY-MM-DD HH:mm:ss");
        this.pushEndTime = this.$moment(val[1]).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.pushStartTime = "";
        this.pushEndTime = "";
      }
    },
    changeTimeList(val) {
      if (val) {
        this.lastStartTime = this.$moment(val[0]).format("YYYY-MM-DD HH:mm:ss");
        this.lastEndTime = this.$moment(val[1]).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.lastStartTime = "";
        this.lastEndTime = "";
      }
    },
  },
  computed: {
    pushTimeList() {
      return this.formInline.pushTime;
    },
    changeTimeList() {
      return this.formInline.lastChangeTime;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.link
  color #6cf
  cursor pointer
</style>
